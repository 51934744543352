.formSelect {
  height: 47px !important;
  border: 1px solid #b6c2e0;
  border-radius: 8px !important;
  &:active {
    border: 1px solid blue;
  }
  &:focus {
    border: 1px solid blue;
    box-shadow: none;
  }
}

.submitButton {
  margin: 0.5rem 0;
  padding: 8px 3rem;
  font-size: 18px;
  font-weight: 500;
  border-radius: 800px;
  border: 1px solid rgb(13, 110, 253);
}

.fileBox {
  background: #f6faff;
  border: 1px dashed #b6c2e0;
  border-radius: 13px;
  max-width: 400px;
  min-height: 163px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 48px 30px 26px;
  margin-bottom: 20px;
  color: #727d9b;
  .browseLink {
    display: inline-block;
    font-weight: 800;
    margin-right: 5px;
  }
  .bottomText {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}