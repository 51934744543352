.sidebar {
  width: 300px;
  border-right: 1px solid #e5eaef;
}
.link {
  height: 47px;
  border-radius: 8px;
  padding: 7px 18px;
  color: black;
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 10px;
  i {
    font-size: 17px;
  }
  &.activeLink {
    background-color: #0d6efd;
    color: white;
  }
}
.icon {
  height: 17px;
}