@import 'bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import 'react-toastify/dist/ReactToastify.css';


a {
  color: inherit;
  text-decoration: none;
}
.btn {
  border-radius: 16px; 
  font-weight: 800; 
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 30px;
}

.btn-counter {
  border-radius: 8px; 
  font-weight: 800; 
  font-size: 18px;
  padding: 5px 10px;
  border: 0px;
  width: 38px;
  height: 38px;
}
.input-counter {
  width: 50px;
}
.minus-btn {
  background-color: red;
  color: white;
}
.add-btn {
  background-color: #198754;
  color: white;
}
.btn-primary {
  background-color: #0d6efd;
  color: white;
}
.add-button {
  background-color: #198754;
  color: white;
  border-radius: 16px;
  padding: 5px 20px;
  font-weight: 800;
  border: 1px solid transparent;
}

.submit-button { 
  // extender .btn
  background-color: #0d6efd;
  color: white;
  border-radius: 16px;
  padding: 5px 20px;
  font-weight: 800;
  border: 1px solid transparent;
  width: 250px;
  font-size: 18px;
  height: 49px;
  border-radius: 30px;
}

.delete-button {
  background-color: red;
  color: white;
  border-radius: 8px;
  padding: 5px 20px;
  font-weight: 800;
  border: 1px solid transparent;
}

.error-message {
  color: red;
  font-weight: 600;
  margin-left: 10px;
}

.form-label {
  font-weight: 500;
}